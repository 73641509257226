import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/ArthSaleDetail'
import styled from '@emotion/styled';
import ReviewSticker from '../../components/reviewSticker'

import { Container, CopperStripe } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'
import { skyBlue, copper, oceanBlue } from '../../constants/theme';

import hero from '../../images/welcome/arthritis-header.jpg'
import momSticker1 from '../../images/welcome/arthritis-gloves.jpg'
import momSticker2 from '../../images/welcome/arthritis-knee.jpg'
import momSticker3 from '../../images/welcome/arthritis-shirt.jpg'

import jointChart from '../../images/welcome/joint-chart.jpg'

import theicon1 from '../../images/welcome/icon-1.png'
import theicon2 from '../../images/welcome/icon-2.png'
import theicon3 from '../../images/welcome/icon-3.png'


import arthDetail from '../../images/welcome/arth-detail.jpg'

const WideImage = styled.img`
  max-width: 800px;
  width: 100%;
  display: block;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
`

const WideImageWrap = styled.div`
  background-color: rgb(250,252,250);
  padding: 60px 20px;
`

const WideImageContain = (props) => (
  <WideImageWrap>
    <WideImage alt={props.alt} src={props.src} />
  </WideImageWrap>
)


export const ArthBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  img {
    width: 100%;
    max-width: 700px;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }

`;

export const TitleBlockStyle = styled.div`

  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 30px 20px 10px 20px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }
`

export const SalesBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 16px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 720px;
    margin: auto;
    margin-bottom: 50px;
    b {
      font-family: 'HKNova-ExtraBold';
    }

    a {
      color: ${oceanBlue};
      text-decoration: none;
    }

  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 21px;
    color: ${copper};
    margin-left: 7px;
  }


  img {
    border-radius: 100%;
  }

`;

const BlockImg = styled.img`
  width: 100%;
`

const ImageBlock = (props) => (
  <>
    <BlockImg alt={props.title} src={props.img} />
  </>
)

const SalesBlock = () => (
  <SalesBlockStyle>
    <h1>May is Arthritis Awareness Month!</h1>
    <p>Arthritis is a term used to describe inflammation, pain, and stiffness in your joints. There are more than 100 different types of arthritis and related conditions that affect different parts of the body. (1)</p>
    <p>Did you know that 23% of adults in the United States have arthritis? That is more than 54 million people! (2) Adults aren't the only people who experience arthritic symptoms. Arthritis can affect people of all ages. According to the Arthritis Foundation, over 300,000 kids and teens have Juvenile Arthritis.(3)</p>
    <p>While there is no cure for arthritis, we do want to help relieve some pain so you can continue to do what you love.</p>
  </SalesBlockStyle>
)

const PainBlock = () => (
  <SalesBlockStyle>
    <h1>Pain Relief Technology</h1>
    <p>Did you know that 23% of adults in the United States have arthritis? That is more that 54 million people! While there is no cure for arthritis, we do want to help relieve some pain so you can continue to do what you love.</p>
    <h6>SOURCE: https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4239152/</h6>
  </SalesBlockStyle>
)

const ArthBlock = () => (
  <ArthBlockStyle>
    <h1>Are Your Joints Healthy?</h1>
    <img src={jointChart} alt='Joint Health' />
  </ArthBlockStyle>
)

const LandingPage = () => {
  return (
    <>
    <CopperStripe />
    <Container>
      <SEO title="Arthritis Awareness Month!" description="Arthritis is a term used to describe inflammation, pain, and stiffness in your joints. There are more than 100 different types of arthritis and related conditions that affect different parts of the body." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />


      <ImageBlock img={hero} title="Mothers Day" />

      <SalesBlock />

      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-fit-for-hands/'}
        img={ momSticker1 }
        bg={ oceanBlue }
        title='The Half Finger Glove'
        caption="“I bought these for my mom, who suffers from Osteoarthritis. Her hands hurt all the time, and it disrupts her sleep. She now wears these gloves all day, and at night. Thank you for making such a great product that gives her relief!”"
        credit="Diane L."

        icon={theicon1}
        uses="Rheumatoid arthritis which immediately effects joints in feet and hands. Knit, sew, draw and craft with ease using our gloves."
        topline='PERFECT RELIEF FROM:'

      />

      <ReviewSticker
        showName
        img={ momSticker2 }
        to={'/products/knee-sleeve-1/'}
        bg={ '#99d8e1' }
        title='The Knee Sleeve'
        caption="“This works great! I have been bothered by arthritis in my knee. I wore this for one night and when I woke up in the morning I could walk without pain. I feel so much better.”"
        credit="Jennifer K."
        icon={theicon2}
        uses="Osteoarthritis which begins with the roughening of cartilage. Our knee sleeve helps to curb pain and stiffening of joints."
        topline='PERFECT RELIEF FROM:'
      />

      <ReviewSticker
        showName
        reverse
        to={'/products/relieve-pain-and-swelling//'}
        img={ momSticker3 }
        bg={ '#8bb1d0' }
        title='Long Sleeve Recovery Shirt'
        caption="“Love your products. I have arthritis and wearing the shirt gives me an added relief so I can reduce my medication!”"
        credit="Maria S."
        icon={theicon3}
        uses="Ankylosing spondylitis can cause pain and swelling, mainly around the joints of the spine. Get relief from your daily movement by wearing our copression recovery shirt."
        topline='PERFECT RELIEF FROM:'
        last
      />

      <WideImageContain src={arthDetail} alt='Arthritis Information' width="100%" />


      <ArthBlock />
      <PainBlock />

      <SaleDetail tag='Arthritis Solutions' title='For relief'/>

    </Container>
    </>
  )
}

export default LandingPage
